import React, { HTMLAttributes, useMemo, useState } from 'react';
import {
    useNavigate, NavLink, useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'app/providers/StoreProvider';
import Bazazavr from 'shared/assets/images/bazazavr.png';
import {
    Container,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
} from '@mui/material';
import { userActions, userStatusSelector } from 'entities/user/model';
import { authActions, authLogout, authSelector } from 'features/Auth/model';
import {
    AppBar, Avatar, Flex, Typography,
} from 'shared/ui';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { PublicService } from 'shared/api';
import {
    ProfileArrow, ArrowDown, ProfileNav, SayAbout, Zabota, ExitNav,
} from 'shared/assets/icons';
import { BazaLogo, BurgerMenu } from 'shared/assets/images';
import { AddBugReportForm } from 'features/AddBugReportForm';
import { NavLinksDropMenu } from './NavLinksDropMenu/NavLinksDropMenu';
import cls from './NavigationBar.module.scss';

interface NavigationBarProps extends HTMLAttributes<HTMLDivElement> {
    userName?: string;
    title?: string;
    isMobile?: boolean;
}

export const NavigationBar: React.FC<NavigationBarProps> = ({
    userName,
    title,
    isMobile,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useAppDispatch();
    const isLoading = useSelector(userStatusSelector) === 'loading';
    const { isAuth } = useSelector(authSelector);
    const navigate = useNavigate();

    const [isModal, setIsModal] = useState(false);
    const [isThanksModal, setIsThanksModal] = useState<boolean>(false);

    const loca = useLocation();

    const isMainPage = (location: string) => location === '/';

    const items = useMemo(
        () => [
            {
                text: 'Профиль',
                link: '/user',
                isLink: true,
                icon: ProfileNav,
            },
            {
                text: 'Сообщить о проблеме',
                link: '/#',
                isLink: false,
                icon: SayAbout,
            },
            {
                text: 'Поддержка',
                link: '/faq',
                isLink: true,
                icon: Zabota,
            },
        ],
        [],
    );

    const navMenuItems = useMemo(
        () => [
            {
                text: 'Главная',
                link: '/',
                icon: null,
            },
            {
                text: 'Оплата услуг',
                link: '/payment',
                icon: null,
            },
            {
                text: 'Мой двор',
                link: '/dvr',
                icon: null,
            },
            {
                text: 'Домофон',
                link: '/intercom',
                icon: null,
            },

            {
                text: 'Карта',
                link: '/map',
                icon: null,
            },
        ],
        [],
    );

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (isAuth) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        if (isAuth) {
            setAnchorEl(null);
        }
    };

    const itemClick = (isLink: boolean, link: string) => {
        if (isLink) {
            navigate(link);
        } else {
            setIsModal(true);
        }
        handleClose();
    };

    const closeModal = () => {
        setIsModal(false);
        setIsThanksModal(false);
    };

    const submitHandler = async (text: string) => {
        try {
            const res = await PublicService.sendBugReport({ note: text });
            setIsThanksModal(res.data.data.result);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('ERROR', e);
        } finally {
            setIsModal(false);
        }
    };

    return (
        <AppBar className={isMobile ? cls.MobileNavigationBar : cls.NavigationBar}>
            <Container>
                <Flex justify="space-between" align="center" className={cls.position}>
                    {isLoading ? (
                        <>
                            <Skeleton height={50} width="75%" />
                            <Skeleton height={35} width={35} variant="circular" />
                        </>
                    ) : (
                        <>
                            {!isMobile
                                ? (
                                    <div className={cls.blockMenu}>

                                        <div className={cls.blockMenuSvg}>
                                            <div className={cls.blockWrap}>
                                                <BurgerMenu className={cls.svgAbs} />
                                                <div
                                                    className={cls.menu__burger}
                                                >
                                                    <NavLinksDropMenu
                                                        menuItem={navMenuItems}
                                                    />
                                                </div>
                                            </div>
                                            <img
                                                onClick={() => navigate('/')}
                                                height={57}
                                                width={77}
                                                style={{ cursor: 'pointer', zIndex: 100 }}
                                                src={BazaLogo}
                                                alt=""
                                            />
                                        </div>
                                        <div className={cls.itemsWrapper}>
                                            {navMenuItems.map((item) => (
                                                <NavLink
                                                    key={item.link}
                                                    to={item.link}
                                                    className={({ isActive }) => (
                                                        isActive
                                                            ? [cls.blockMenu__ItemBlock, cls.active].join(' ')
                                                            : cls.blockMenu__ItemBlock
                                                    )}
                                                >
                                                    {item.text}
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                )
                                : (
                                    // eslint-disable-next-line react/jsx-no-useless-fragment
                                    <>
                                        {!isMainPage(loca.pathname)
                                            ? (
                                                <div className={cls.mobBlockMenu}>
                                                    <div
                                                        className={cls.mobBlockMenu__btn}
                                                        onClick={() => navigate(-1)}
                                                    >
                                                        <ProfileArrow />
                                                    </div>
                                                    <div className={cls.mobBlockMenu__title}>{title}</div>
                                                </div>
                                            ) : (
                                                <div className={cls.mobBlockGreet}>
                                                    Привет,
                                                    {' '}
                                                    {userName}
                                                    !
                                                </div>
                                            )}
                                    </>
                                )}

                            <div className={cls.blockAvatar} onClick={handleClick}>
                                {!isMobile && (
                                    <>
                                        <Typography variant="h2" className={cls.blockAvatar__title}>
                                            {userName}
                                        </Typography>
                                        <ArrowDown width={20} className={cls.blockAvatar__arrow} />
                                    </>
                                )}
                                <Avatar>
                                    <img width="100%" src={Bazazavr} alt="Avatar" />
                                </Avatar>
                            </div>
                        </>
                    )}
                </Flex>
            </Container>
            <Menu
                id="menu"
                aria-labelledby="button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {items.map((item) => (
                    <MenuItem
                        key={item.link}
                        onClick={() => {
                            itemClick(item.isLink, item.link);
                        }}
                    >
                        {!isMobile && (
                            <ListItemIcon>
                                <item.icon />
                            </ListItemIcon>
                        )}
                        <ListItemText>
                            <Typography variant="body">{item.text}</Typography>
                        </ListItemText>
                    </MenuItem>
                ))}
                <Divider />
                <MenuItem
                    onClick={async () => {
                        handleClose();
                        await dispatch(authLogout());
                        dispatch(userActions.resetProfile());
                        dispatch(authActions.removeUser());
                        localStorage.clear();
                    }}
                >
                    {!isMobile && (
                        <ListItemIcon>
                            <ExitNav />
                        </ListItemIcon>
                    )}
                    <ListItemText>
                        <Typography variant="body">Выйти</Typography>
                    </ListItemText>
                </MenuItem>
            </Menu>

            <AdaptiveModal
                open={isModal}
                onClose={closeModal}
            >
                <AddBugReportForm submitHandler={submitHandler} close={closeModal} />
            </AdaptiveModal>

            <AdaptiveModal
                open={isThanksModal}
                onClose={closeModal}
            >
                <div className={cls.successBlock}>
                    <Typography variant="h3">
                        Спасибо
                    </Typography>
                    <Typography variant="body" className={cls.heading}>
                        Отчет отправлен.
                    </Typography>
                </div>
            </AdaptiveModal>

        </AppBar>
    );
};
