import { useSelector } from 'react-redux';
import { authSelector, LoginForm, useDelayLogin } from 'features/Auth';
import {
    Button, Card, Flex, Typography,
} from 'shared/ui';
import { numberSelector } from 'entities/variables/model';
import { useAppSelector } from 'app/providers/StoreProvider';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { AuthQuestion } from 'shared/assets/icons';
import { useState } from 'react';
import { AdaptiveModal } from 'shared/ui/AdaptiveModal/AdaptiveModal';
import { NumberInfo } from 'widgets/NumberInfo/ui/NumberInfo';
import {
    AppStoreLink,
    authImageFirst, authImageSecond, authImageThird, BazaBigLogo, GooglePlayLink,
} from 'shared/assets/images';
import cls from './AuthPage.module.scss';

const AuthPage = () => {
    const [isModal, setIsModal] = useState<boolean>(false);
    const { error: authError } = useAppSelector(authSelector);
    const {
        loading: loginLoading,
        error: loginError,
        delayLogin,
        cancel,
    } = useDelayLogin(1000 * 60 * 5);
    const infoNumber = useSelector(numberSelector);
    const { isMobile } = useDeviceDetect();
    const numberDecorator = (num: string) => `${num.slice(0, 1)}-${num.slice(1, 4)}-${num.slice(4, 8)}-${num.slice(8)}`;
    const telNumber: string = (infoNumber && numberDecorator(infoNumber)) || '8-800-1000-249';
    const [telAuth, setTelAuth] = useState(true);

    return (
        <div className={cls.wrapper}>
            <div className={cls.authPage}>
                <div className={cls.authPage_images}>
                    <div className={cls.bazaLogo}>
                        <img src={BazaBigLogo} alt="bazaLogo" />
                    </div>
                    <div className={cls.authTitle}>Личный кабинет</div>
                    <div className={cls.titleImages}>
                        <p>Управляйте своими услугами в одном месте</p>
                        <div className={cls.authImg}>
                            <img src={authImageFirst} alt="" />
                            <img src={authImageSecond} alt="" />
                            <img src={authImageThird} alt="" />
                        </div>
                    </div>
                </div>
                <div>
                    <Card className={cls.authCard}>
                        <div className={cls.authPage_wrapp}>
                            <div className={cls.heading}>
                                Авторизация
                            </div>
                            <div className={cls.telOrIp}>
                                <div
                                    className={`${cls.text} ${telAuth ? cls.active : ''}`}
                                    onClick={() => setTelAuth(true)}
                                >
                                    Вход по номеру телефона
                                </div>
                                <div
                                    className={`${cls.text} ${!telAuth ? cls.active : ''}`}
                                    onClick={() => setTelAuth(false)}
                                >
                                    Вход по Wi-Fi
                                </div>
                            </div>
                            {loginLoading ? (
                                <>
                                    <div className={cls.freePhone}>
                                        <div className={cls.freeTitle}>Позвоните на бесплатный номер</div>
                                        <div className={cls.numberCircle}>
                                            {telNumber}
                                            <div className={cls.numberCircle__circle} onClick={() => setIsModal(true)}>
                                                <AuthQuestion />
                                            </div>
                                        </div>
                                        <div className={cls.freeDetect}>
                                            Мы определим Ваш номер и сразу завершим вызов
                                        </div>
                                    </div>
                                    <div className={!isMobile ? cls.Buttons : cls.Buttons__call}>
                                        {isMobile && (
                                            <a
                                                href={`tel:${telNumber}`}
                                                className={cls.Buttons__call}
                                            >
                                                <Button
                                                    className={cls.cancelBtn}
                                                    fullwidth
                                                >
                                                    Позвонить
                                                </Button>
                                            </a>
                                        )}
                                        <Button
                                            className={cls.cancelBtn}
                                            fullwidth
                                            variant="secondary"
                                            onClick={cancel}
                                        >
                                            Отмена
                                        </Button>
                                    </div>
                                </>

                            ) : (
                                <>
                                    {authError && (
                                        <Typography style={{ color: 'red' }} variant="body" align="center">
                                            {authError}
                                        </Typography>
                                    )}
                                    {loginError && (
                                        <Typography style={{ color: 'red' }} variant="body" align="center">
                                            {loginError}
                                        </Typography>
                                    )}
                                    <LoginForm
                                        className={cls.form}
                                        loading={loginLoading}
                                        error={loginError}
                                        telAuth={telAuth}
                                        delayLogin={delayLogin}
                                    />
                                </>
                            )}
                        </div>
                    </Card>
                    <div className={cls.installApp}>
                        <p>Установить приложение</p>
                        <Flex justify="center" gap={12}>
                            <a href="https://play.google.com/store/apps/details?id=net.baza.bazanetclientapp">
                                {' '}
                                <img src={GooglePlayLink} alt="" />
                            </a>
                            <a href="https://apps.apple.com/app/id1540912517">
                                <img src={AppStoreLink} alt="" />
                            </a>
                        </Flex>
                    </div>
                </div>
                <AdaptiveModal
                    open={isModal}
                    onClose={() => setIsModal(false)}
                >
                    <NumberInfo />
                </AdaptiveModal>
            </div>
            <div className={cls.appVersion}>Версия № 2.11</div>
        </div>
    );
};
export default AuthPage;
