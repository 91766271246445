import { useEffect, useMemo } from 'react';
import {
    CardWithIcon, Flex,
} from 'shared/ui';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import 'swiper/css';
import 'react-spring-bottom-sheet/dist/style.css';
import {
    contractsSelector,
    userStatusSelector,
} from 'entities/user/model/selectors';
import { useAppSelector } from 'app/providers/StoreProvider';
import useDeviceDetect from 'shared/hooks/useDeviceDetect/useDeviceDetect';
import { ConnectToInternet } from 'widgets/ConnectToInternet';
import { HomePageLoader } from 'widgets/HomePageLoader';
import { ArrowForward } from 'shared/assets/icons';
import { Container } from '@mui/material';
import { AccountList } from 'widgets/AccountList';
import { classNames } from 'shared/lib';
import {
    HomeCamera, HomeIntercom, HomeMap, HomeMobile, HomeTv,
} from 'shared/assets/images';
import cls from './HomePage.module.scss';

const HomePage = () => {
    const navigate = useNavigate();
    const status = useSelector(userStatusSelector);
    const contracts = useAppSelector(contractsSelector);
    const { isMobile } = useDeviceDetect();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const uslugi = useMemo(
        () => (
            <>
                <CardWithIcon
                    icon={HomeTv}
                    title="Интернет и ТВ"
                    text="Подключите услуги на выгодных условиях!"
                    className={cls.cardHomePage}
                    onClick={() => navigate('/internet')}
                    isFirst
                />
                <CardWithIcon
                    icon={HomeMobile}
                    title="Мобильная связь"
                    text="Всегда оставайтесь на связи с любимыми!"
                    className={cls.cardHomePage}
                    onClick={() => navigate('/mobile')}
                />
            </>
        ),
        [contracts],
    );
    const smartServices = useMemo(
        () => (
            <>
                <CardWithIcon
                    icon={HomeCamera}
                    title="Мой двор"
                    text="Смотрите камеры во дворе и свободную парковку!"
                    className={cls.cardHomePage}
                    onClick={() => navigate('/dvr')}
                />

                <CardWithIcon
                    icon={HomeIntercom}
                    title="Умный домофон"
                    text="Знайте, кто звонит Вам в дверь!"
                    className={cls.cardHomePage}
                    onClick={() => navigate('/intercom')}
                />
                <CardWithIcon
                    icon={HomeMap}
                    title="Карта"
                    text="Посмотри, что происходит в твоем городе!"
                    className={cls.cardHomePage}
                    onClick={() => navigate('/map')}
                />

            </>
        ),
        [contracts],
    );

    if (status === 'loading') {
        return (
            <div className={cls.HomePage}>
                <HomePageLoader />
            </div>
        );
    }

    return (
        <Container disableGutters>
            <div className={cls.HomePage}>
                {/* todo Лицевые счета */}
                <div className={cls.uidCard}>
                    {!isMobile && <div className={cls.accList}>Лицевые счета</div>}
                    <AccountList contracts={contracts} isMobile={isMobile} />
                </div>
                {/* todo content */}
                <div className={classNames(cls.container, {}, [])}>
                    {/* {!isMobile && (
                        <div className={cls.container_ads}>
                            <Advertisement />
                        </div>
                    )} */}
                    {!isMobile && (
                        <div className={cls.container_title}>
                            Подключите наши услуги
                            <ArrowForward />
                        </div>
                    )}

                    <div className={cls.cardGrid}>
                        {uslugi}
                    </div>
                    {!isMobile && (
                        <div className={cls.container_title}>
                            Умные сервисы
                            <ArrowForward />
                        </div>
                    )}
                    <div className={cls.cardGrid}>
                        {smartServices}
                    </div>
                    {/* todo Подключить услуги */}
                    <Flex justify="center">
                        <ConnectToInternet />
                    </Flex>
                </div>

            </div>
        </Container>
    );
};

export default HomePage;
