import React, {
    useEffect,
    useRef,
    useState,
} from 'react';
import { classNames } from 'shared/lib/classNames';
import {
    Button, Typography,
} from 'shared/ui/index';
import { LinearProgress } from '@mui/material';
import { Lock, Locked, PlayDvr } from 'shared/assets/icons';
import cls from './CameraPreview.module.scss';

interface CameraPreviewProps {
  image: string;
  link: string;
  title: string;
  className?: string;
  fullControl?:boolean;
  cameraCount?:number;
  type?: 'intercom' | 'dvr';
  onClickLock?: () => Promise<{ data: { status: boolean } }>;
  onClickPlay?: () => void;
  copyToClipboard?: any;
}

export const CameraPreview: React.FC<CameraPreviewProps> = ({
    className,
    link,
    image,
    title,
    type = 'dvr',
    fullControl,
    cameraCount,
    onClickLock,
    onClickPlay,
    copyToClipboard,
    ...props
}) => {
    const svgRef = useRef<SVGSVGElement>(null);
    const [animated, setAnimated] = useState(false);
    const [progress, setProgress] = useState(0);

    const onClickHandler = () => {
        setProgress(0);
        onClickLock?.()
            .then((res) => {
                setAnimated(true);
                if (res.data.status === true) {
                    setTimeout(() => {
                        setAnimated(false);
                    }, 10000);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const onPlayHandler = () => {
    // window.open(link, '_blank');
        onClickPlay?.();
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 100;
                }
                const diff = 5;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={classNames(cls.CameraPreview, {}, [className])}>
            <div className={cls.title}>
                <Typography variant="h3">{title}</Typography>
                {cameraCount && (
                    <Typography variant="body">
                        По тому адресу вам доступно
                        {' '}
                        {cameraCount}
                        {' '}
                        камер
                    </Typography>
                )}
            </div>
            <div className={cls.preview}>
                <div className={cls.img}>
                    <img
                        className={cls.img}
                        src={image}
                        alt="камера домофона"
                    />
                    <div className={cls.controls}>
                        <div className={cls.playWrapper}>
                            <PlayDvr className={cls.play} onClick={onPlayHandler} />
                        </div>
                        {type === 'intercom' && fullControl && (
                            <Button variant="link" disabled={animated} className={cls.lockStyle}>
                                {!animated
                                    ? (
                                        <Locked
                                            onClick={onClickHandler}
                                            className={cls.lock}
                                        />
                                    )
                                    : (
                                        <>
                                            <Lock
                                                onClick={onClickHandler}
                                                className={classNames(cls.lock, { [cls.open]: animated })}
                                                ref={svgRef}
                                            />
                                            <LinearProgress variant="determinate" value={progress} />
                                        </>
                                    )}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
